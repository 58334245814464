import React from "react";
import ParadisoMap from "@/components/ui/VenueMaps/ParadisoMap";
import TeranMap from "@/components/ui/VenueMaps/TeranMap";
import {
  KingsLoungeFirstFloor,
  KingsLoungeSecondFloor
} from "@/components/ui/VenueMaps/KingsLoungeMap";

export interface MapArea {
  id: string;
  name: string;
  mapId: string;
  map: React.ReactNode;
}

export interface VenueMap {
  id: string;
  slug: string;
  areas: MapArea[];
}

const venueMaps: VenueMap[] = [
  {
    id: "8b3e48ba-9078-4276-9bef-3185193aae69",
    slug: "paradiso",
    areas: [
      {
        name: "Main Area",
        mapId: "paradiso-table-reservation",
        id: "75af780c-a1b1-4f63-ad02-afaf84f2e061",
        map: <ParadisoMap />
      }
    ]
  },
  {
    id: "37a03d0c-3377-4985-a4b8-bb790542b1cb",
    slug: "teran",
    areas: [
      {
        id: "42542204-8475-4882-a709-e47d5dcec7f2",
        name: "Main Area",
        mapId: "teran-lounge-bar",
        map: <TeranMap />
      }
    ]
  },
  {
    id: "d2a38ab3-c673-434c-8364-7eeaea11abe0",
    slug: "kings-lounge-pokhara",
    areas: [
      {
        id: "46e3751d-9905-4bc4-a061-4c07521e99c9",
        name: "Main Area",
        mapId: "kings-lounge-table-reservation-1st-floor",
        map: <KingsLoungeFirstFloor />
      },
      {
        id: "5e10737d-02e2-4c37-8901-64f8097a3ab2",
        name: "Main Area",
        mapId: "kings-lounge-table-reservation-2nd-floor",
        map: <KingsLoungeSecondFloor />
      }
    ]
  }
];

export default venueMaps;
